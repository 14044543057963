import {
	Accordion,
	AccordionDetails,
	Alert,
	AlertColor,
	Backdrop,
	Button,
	CircularProgress,
	Grid,
	Paper,
	Radio,
	RadioGroup,
	Snackbar,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Typography,
	styled
} from '@mui/material';
import MuiAccordionSummary, { AccordionSummaryProps } from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { DateTime } from 'luxon';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { ACCORDION, CREATE_SHIPMENTS, HEADING, IMAGE, KEY, LABEL, RADIO_BUTTON, RADIO_GROUP, SERVICE, TABLE, TABLE_BODY, TABLE_CELL, TABLE_HEAD, TABLE_ROW, VALUE, createElementId } from '../../../../constants/id';
import Header from '../../../Layout/Header';

export default function ServiceForm(props: any) {
	const shipment = props.getValues();
	const setValue = props.setValue;
	const setSelectedRate = props.setSelectedRate;
	const [openSnackBar, setOpenSnackBar] = useState(false);
	const [errorAlert, setErrorAlert] = useState(false);
	const [alertErrorMessage, setAlertErrorMessage] = useState('');
	const [pickupErrorMessage, setPickupErrorMessage] = useState('');
	const [loading, setLoading] = useState(false);
	const theme = useTheme();
	const isXsSmMd = useMediaQuery(theme.breakpoints.up('md'));
	const [rates, setRates] = useState(
		props.rates?.length > 0
			? props.rates.sort((a: any, b: any) => {
					if (a.service === 'Ground') {
						return -1;
					} else if (b.service === 'Ground') {
						return 1;
					} else {
						return 0;
					}
			  })
			: []
	);
	const [selectedService, setSelectedService] = useState(shipment.service);
	const [apiResponse, setApiResponse] = useState<{
		type: AlertColor;
		message: string;
	}>({ type: 'success', message: '' });

	const Img = styled('img')({
		display: 'block',
		maxWidth: '140px',
		maxHeight: '140px',
		padding: '10px'
	});
	const navigate = useNavigate();

	const handleServiceChange = (event: React.ChangeEvent<HTMLInputElement>, rate: any) => {
		setSelectedService(event.target.value);
		setValue('service', event.target.value);
		setValue('carrier_accounts', [rate.carrier_account_id]);
		setValue('shipment_type', rate.service === 'Ground' ? 'economy' : 'express');
		setValue('rate', rate.rate);
		setValue('carrier', rate.carrier);
		setSelectedRate(rate);
	};

	const handleAlertClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
		if (reason === 'clickaway') {
			return;
		}
		setOpenSnackBar(false);
	};

	const AccordionSummary = styled((props: AccordionSummaryProps) => (
		<MuiAccordionSummary expandIcon={<ExpandMoreIcon />} {...props} />
	))(() => ({
		'& .MuiAccordionSummary-expandIconWrapper': {
			paddingBottom: '50%',
			paddingTop: '50%',
			marginTop: '200%',
			marginBottom: '200%'
		},
		'& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
			transform: 'rotate(180deg)'
		}
	}));

	useEffect(() => {
		if (shipment.service) {
			const rate = rates.find((rate: any) => rate.service === shipment.service);
			if (rate) {
				setValue('carrier_accounts', [rate.carrier_account_id]);
				setValue('shipment_type', rate.service === 'Ground' ? 'economy' : 'express');
				setValue('rate', rate.rate);
				setValue('carrier', rate.carrier);
				setSelectedRate(rate);
			} else {
				setValue('carrier_accounts', []);
				setValue('shipment_type', '');
				setValue('rate', '');
				setValue('carrier', '');
				props.setSelectedRate(null);
			}
		}
	});

	return (
		<>
			<div
				style={{
					display: 'block',
					justifyContent: 'center',
					alignContent: 'center',
					marginBottom: '60px',
					maxWidth: 900,
					marginLeft: 'auto',
					marginRight: 'auto'
				}}
			>
				<Grid container direction={'column'} textAlign={'center'}>
					<Typography 
						id={createElementId([CREATE_SHIPMENTS, SERVICE, HEADING, LABEL, 'select_service'])}
						variant="h5" mb={5} mt={5}>
						Select a Service{' '}
					</Typography>
				</Grid>
				<Grid
					container
					direction="column"
					alignContent="center"
					justifyContent="center"
					textAlign={'left'}
					lineHeight={5}
					spacing={2}
				>
					{!rates.length ? (
						<Alert severity={'info'}>{`We're sorry, but we couldn't find any rates.`}</Alert>
					) : (
						<>
							{rates.map((rate: any) => (
								<Accordion
								id={createElementId([CREATE_SHIPMENTS, SERVICE, ACCORDION, rate.carrier])}
									elevation={5}
									style={{
										width: '100%',
										marginBottom: 10
									}}
								>
									<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
										<RadioGroup
										id={createElementId([CREATE_SHIPMENTS, SERVICE, ACCORDION, rate.carrier, RADIO_GROUP, 'select_service'])}
											aria-labelledby="demo-radio-buttons-group-label"
											defaultValue="female"
											name="radio-buttons-group"
										>
											<Grid
												container
												direction="row"
												alignContent={'center'}
												justifyContent={'center'}
											>
												<Grid
													item
													alignSelf={'flex-start'}
													justifyContent={'flex-start'}
													direction={'column'}
												>
													{isXsSmMd ? <AccordionSummary></AccordionSummary> : ''}
												</Grid>
												<Grid item alignSelf={'center'} xs={4} sm={4} md={3} lg={3} xl={3}>
													<Img
														id={createElementId([CREATE_SHIPMENTS, SERVICE, ACCORDION, rate.carrier, IMAGE, 'service_logo'])}
														src={`../img/${rate.carrier}.${
															rate.service === 'Ground' ? 'png' : 'jpg'
														}`}
													/>
												</Grid>
												<Grid item alignSelf={'center'} xs={4} sm={4} md={4} lg={4} xl={4}>
													<Grid
														container
														direction={'column'}
														justifyContent={'start'}
														alignContent={'start'}
													>
														<Grid item>
															<Typography
																id={createElementId([CREATE_SHIPMENTS, SERVICE, ACCORDION, rate.carrier, HEADING, LABEL, rate.carrier])}
																variant={'h5'}>
																{rate.service === 'FEDEX_INTERNATIONAL_PRIORITY'
																	? 'Fedex International Priority'
																	: rate.service === 'UPSSaver'
																	? 'UPS Saver'
																	: rate.service === 'Ground'
																	? 'TUNL Economy'
																	: ''}
															</Typography>
														</Grid>
														<Grid item>
															<Typography
																id={createElementId([CREATE_SHIPMENTS, SERVICE, ACCORDION, rate.carrier, LABEL, 'est_number_delivery_days'])}>
																{`Est. Delivery Days: ` +
																	(rate.service === 'Ground' ? `10-14` : `4-6`)}
															</Typography>
														</Grid>
														<Grid item>
															<Typography
															id={createElementId([CREATE_SHIPMENTS, SERVICE, ACCORDION, rate.carrier, LABEL, 'est_delivery_date'])}
															>
																{`Est. Delivery Date: ` +
																	(rate.service === 'Ground'
																		? DateTime.now()
																				.plus({ days: 14 })
																				.toFormat('dd-MM-yyyy')
																		: DateTime.now()
																				.plus({ days: 6 })
																				.toFormat('dd-MM-yyyy'))}
															</Typography>
														</Grid>
														<Grid item>
															{' '}
															<Typography
																id={createElementId([CREATE_SHIPMENTS, SERVICE, ACCORDION, rate.carrier, LABEL, 'delivery_type'])}
															>
																{rate.service === 'Ground'
																	? 'Drop-Off Only'
																	: 'Pickup Included'}
															</Typography>
														</Grid>
													</Grid>
												</Grid>
												<Grid
													item
													alignSelf={'center'}
													textAlign={'center'}
													xs={2}
													sm={2}
													md={2}
													lg={2}
													xl={2}
												>
													<Typography
														id={createElementId([CREATE_SHIPMENTS, SERVICE, ACCORDION, rate.carrier, LABEL, 'service_fee'])}
													>{'R' + Number(rate.rate).toFixed(2)}</Typography>
												</Grid>
												{isXsSmMd ? (
													<Grid
														item
														alignSelf={'center'}
														xs={12}
														sm={12}
														md={1}
														lg={1}
														xl={1}
													>
														{/* <Button
														variant={'contained'}
														sx={{
															backgroundColor: '#03C988',
															':hover': {
																backgroundColor: '#057852'
															}
														}}
														size={'large'}
														onClick={() => handleBuyShipment(rate)}
													>
														Buy
													</Button> */}
														<Radio
															id={createElementId([CREATE_SHIPMENTS, SERVICE, ACCORDION, rate.carrier, RADIO_BUTTON, 'select_service'])}
															checked={selectedService === rate.service}
															onChange={e => {
																handleServiceChange(e, rate);
															}}
															value={rate.service}
															name="radio-buttons"
															style={{ marginLeft: '5%' }}
															// inputProps={{ 'aria-label': 'B' }}
														/>
													</Grid>
												) : (
													<Grid item alignSelf={'center'} xs={1} sm={1} md={1} lg={1} xl={1}>
														<Radio
															id={createElementId([CREATE_SHIPMENTS, SERVICE, ACCORDION, rate.carrier, RADIO_BUTTON, 'select_service'])}
															checked={selectedService === rate.service}
															onChange={e => {
																handleServiceChange(e, rate);
															}}
															value={rate.service}
															name="radio-buttons"
															style={{ marginLeft: '5%', scale: '1.6' }}
															// inputProps={{ 'aria-label': 'B' }}
														/>
													</Grid>
												)}
											</Grid>
										</RadioGroup>
									</Grid>

									<>
										<AccordionDetails>
											<Grid container direction={'column'} textAlign={'center'}>
												<TableContainer elevation={4} component={Paper}>
													<Table 
														id={createElementId([CREATE_SHIPMENTS, SERVICE, ACCORDION, rate.carrier, TABLE, 'service_cost_breakdown'])}		
														aria-label="collapsible table">
														<TableHead>
															<TableRow 
																id={createElementId([CREATE_SHIPMENTS, SERVICE, ACCORDION, rate.carrier, TABLE, TABLE_ROW, TABLE_HEAD, 'cost_breakdown'])}>
																<TableCell
																id={createElementId([CREATE_SHIPMENTS, SERVICE, ACCORDION, rate.carrier, TABLE, TABLE_ROW, TABLE_CELL, KEY, 'item'])}
																colSpan={2}>Item</TableCell>
																<TableCell 
																id={createElementId([CREATE_SHIPMENTS, SERVICE, ACCORDION, rate.carrier, TABLE, TABLE_ROW, TABLE_CELL, KEY, 'notes'])}
																align="right">Notes</TableCell>
																<TableCell 
																id={createElementId([CREATE_SHIPMENTS, SERVICE, ACCORDION, rate.carrier, TABLE, TABLE_ROW, TABLE_CELL, KEY, 'amount'])}
																align="right">Amount (R)</TableCell>
															</TableRow>
														</TableHead>
														<TableBody>
															{rate.rate_breakdown.map((newRate: any, i: number) => (
																<TableRow
																key={createElementId([CREATE_SHIPMENTS, SERVICE, ACCORDION, rate.carrier, TABLE, TABLE_ROW ,TABLE_BODY, newRate.note])}
																id={createElementId([CREATE_SHIPMENTS, SERVICE, ACCORDION, rate.carrier, TABLE, TABLE_ROW ,TABLE_BODY, newRate.note])}
																	sx={{
																		'& > *': {
																			borderBottom: 'unset'
																		}
																	}}
																>
																	<TableCell
																	id={createElementId([CREATE_SHIPMENTS, SERVICE, ACCORDION, rate.carrier, TABLE, TABLE_ROW, TABLE_CELL, VALUE, `${i+1}`])}
																	colSpan={2} component="th" scope="row">
																		{i + 1}
																	</TableCell>
																	
																	<TableCell
																	id={createElementId([CREATE_SHIPMENTS, SERVICE, ACCORDION, rate.carrier, TABLE, TABLE_ROW, TABLE_CELL, VALUE, newRate.note])}
																	align="right">
																		{newRate.carrierCode}
																	</TableCell>
																	<TableCell 
																	id={createElementId([CREATE_SHIPMENTS, SERVICE, ACCORDION, rate.carrier, TABLE, TABLE_ROW, TABLE_CELL, VALUE, newRate.note, 'amount'])}
																	align="right">
																		{newRate.amount.toFixed(2)}
																	</TableCell>
																</TableRow>
															))}
															<TableRow id={createElementId([CREATE_SHIPMENTS, SERVICE, ACCORDION, rate.carrier, TABLE, TABLE_ROW ,TABLE_BODY, 'total'])}>
																<TableCell colSpan={2}></TableCell>
																<TableCell 
																id={createElementId([CREATE_SHIPMENTS, SERVICE, ACCORDION, rate.carrier, TABLE, TABLE_ROW, TABLE_CELL, KEY, 'total'])}
																align="right" colSpan={1}>
																	Total
																</TableCell>
																<TableCell 
																id={createElementId([CREATE_SHIPMENTS, SERVICE, ACCORDION, rate.carrier, TABLE, TABLE_ROW, TABLE_CELL, VALUE, 'total'])}
																align="right">{rate.rate}</TableCell>
															</TableRow>
														</TableBody>
													</Table>
												</TableContainer>
											</Grid>
										</AccordionDetails>
									</>
								</Accordion>
							))}
						</>
					)}
				</Grid>
				<Snackbar
					open={openSnackBar}
					autoHideDuration={2000}
					onClose={handleAlertClose}
					anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
				>
					<Alert onClose={handleAlertClose} severity={apiResponse.type} sx={{ width: '100%' }}>
						{apiResponse.message}
					</Alert>
				</Snackbar>
				<Backdrop sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 1 }} open={loading}>
					<CircularProgress color="inherit" />
				</Backdrop>
			</div>
		</>
	);
}
